<template>
  <div class="container_detail">
    <clone-tab></clone-tab>

    <!-- 地址信息 -->
    <address-from :dataFrom="dataFrom" :addressText="addressText" @watchChange="watchChange" @selectAddress="Saddress"></address-from>

    <!-- 默认 -->
    <div class="default">
      <div class="ot_text">
        <div class="ot_title">设为默认收件地址</div>
        <div>(下单是默认选择地址)</div>
      </div>
      <div class="kg_switch">
        <van-switch v-model="checked" size="24px" active-color="#EED09C" inactive-color="#222222" />
      </div>
    </div>

    <div class="addresssubmit" @click="submitBtn">保存</div>
    <van-area title="" v-if="addressShow" :area-list="areaList" @confirm="selectBtn" @cancel="addressShow=false" :value="value" />
  </div>
</template>
<script>
import cloneTab from "../../components/expenditure/cloneTab";
import addressFrom from "../../components/addressFrom";
import { areaList } from "@vant/area-data";
import { addAddress_api, getAddress_api,detailAddress_api } from "@/api/deal";
export default {
  data() {
    return {
      dataFrom: {
        receiver_name: { value: "", placeholder: "请输入收件人" },
        receiver_mobile: { value: "", placeholder: "请输入联系方式" },
        receiver_address: { value: "", placeholder: "请输入详细地址" }
      },
      checked: false,
      areaList: null,
      addressShow: false,
      addressList: "",
      addressText: "",
      queryList: null,
      value:''
    };
  },
  components: {
    cloneTab,
    addressFrom
  },
  created() {
    console.log(this.queryList);
    this.queryList = this.$route.query;
    document.title = "编辑地址";
    this.areaList = areaList;
    if (this.queryList.id) {
      this.getDetails();
    }
  },
  methods: {
    getDetails() {
      getAddress_api(this.queryList.id).then(res => {
        if (res.code == 0) {
          this.dataFrom.receiver_name.value = res.data.receiver_name;
          this.dataFrom.receiver_mobile.value = res.data.receiver_mobile;
          this.dataFrom.receiver_address.value = res.data.receiver_address;
          this.checked = res.data.is_default?true:false;
          this.value = res.data.area.code;
          this.addressText = res.data.province.name + res.data.city.name + res.data.area.name
          this.addressList = [res.data.province, res.data.city, res.data.area]
        }
      });
    },
    Saddress() {
      this.addressShow = true;
    },
    selectBtn(e) {
      console.log(e, "12");
      this.addressList = e;
      this.addressText = e[0].name + e[1].name + e[2].name;
      this.addressShow = false;
    },
    // 表单数据
    watchChange(from) {
      console.log(from, "12314");
      // this.tableData = from;
    },
    // 數據提交
    submitBtn() {
      let data = {
        receiver_name: this.dataFrom.receiver_name.value,
        receiver_mobile: this.dataFrom.receiver_mobile.value,
        province: this.addressList[0],
        city: this.addressList[1],
        area: this.addressList[2],
        receiver_address: this.dataFrom.receiver_address.value,
        is_default: this.checked ? 1 : 0
      };
      console.log(data, "12314");
      for(var i in data){
        if(i!='is_default'){
          if(!data[i]){
          this.$toast("请完善表单");
          return false
        }
        }
        
      }
       var myreg = /^[1][0-9]{10}$/;
      if (!myreg.test(this.dataFrom.receiver_mobile.value)) {
        this.$toast("请输入正确的手机号");
        return false
      }
      if(this.queryList.id){
        detailAddress_api(this.queryList.id, data).then(res=>{
          if(res.code==0){
            this.$router.go(-1)
          }
        })
      }else{
        addAddress_api(data).then(res => {
        if (res.code == 0) {
          this.$router.go(-1);
        }
      });
      }
      
    }
  }
};
</script>
<style>
.container_detail {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/Slice51.png);
  background-size: 100% 100%;
  padding-top: 30px;
}
.default {
  display: flex;
  justify-content: space-between;
  font-size: 30px;
  color: #fff;
}
.ot_text {
  margin-left: 28px;
}
.ot_title {
  font-size: 34px;
  color: #d6b06f;
}
.kg_switch {
  margin-right: 47px;
}
.addresssubmit {
  width: 489px;
  height: 80px;
  background: #d6b06f;
  box-shadow: inset 0px 4px 5px 0px rgba(255, 255, 255, 0.3);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  font-size: 30px;
  color: #fff;
  text-align: center;
  line-height: 88px;
  margin: 150px auto 30px;
}
.van-ellipsis {
  font-size: 30px;
}
.van-picker__cancel,
.van-picker__confirm {
  font-size: 30px;
}
.van-picker {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
