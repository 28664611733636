<template>
  <div class="content_b">
    <div class="from_list">
      <div class="to_list">收件人：</div>
      <input v-model="dataFrom.receiver_name.value" placeholder="请输入收件人" />
    </div>
    <div class="from_list">
      <div class="to_list">联系方式：</div>
      <input v-model="dataFrom.receiver_mobile.value" placeholder="请输入联系方式" />
    </div>
    <div class="from_list">
      <div class="to_list">地址区域：</div>
      <input v-model="addressText" @focus="selectAddress1" placeholder="省市区" />
    </div>
    <div class="from_list">
      <div class="to_list">详细地址：</div>
      <textarea v-model="dataFrom.receiver_address.value" placeholder="请输入详细地址" class="textarea"></textarea>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataList: {
        
        addresList: null
      }
    };
  },
  props: {
    addressText: {
      type: String
    },
    dataFrom: {
      type: Object
    }
  },
  watch: {
    dataList: {
      handler(newV, oldV) {
        console.log("watch深度监听：", newV, oldV);
      },
      deep: true
    }
  },
  mounted() {},
  methods: {
    selectAddress1() {
      this.$emit("selectAddress");
    }
  }
};
</script>
<style>
.from_list input {
  width: 500px;
  height: 80px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid #ccaf7c;
  background-color: transparent;
  padding-left: 20px;
  box-sizing: border-box;
}
.from_list {
  display: flex;
  font-size: 34px;
  color: #d6b06f;
  line-height: 80px;
  margin-bottom: 50px;
}
.to_list {
  width: 200px;
  text-align: right;
}
.content_b {
  margin-top: 100px;
}
.textarea {
  width: 500px;
  height: 300px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid #ccaf7c;
  background-color: transparent;
  padding: 20px;
  box-sizing: border-box;
}
</style>